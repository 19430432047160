import * as Yup from 'yup';
import { filter, truncate } from 'lodash';
import ReactDOM from 'react-dom';
import QRCode from 'react-qr-code';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  TextField,
  Box,
  CircularProgress,
  Tooltip
} from '@material-ui/core';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getUserList, deleteUser } from '../../redux/slices/user';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';
import LoadingScreen from 'src/components/LoadingScreen';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user/list';
import  ProductToolbar  from 'src/components/_dashboard/user/list/ProductToolbar.js';
import  ProductsSearch  from 'src/components/_dashboard/blog/ProductsSearch.js';
import  ProductsMenu  from 'src/components/_dashboard/user/list/ProductsMenu.js';
import axios from 'axios';
import {REST_API, REST_API_END_POINT, UNITS} from '../../constants/Defaultvalues';
import moment from 'moment';
import MaterialInventoryMenu from 'src/components/_dashboard/user/list/MaterialInventoryMenu';
import MaterialInventoryToolbar from 'src/components/_dashboard/user/list/MaterialInventoryToolbar';
import Modal from 'src/components/_dashboard/blog/ProjectModel';
import ReactToPrint from 'react-to-print';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { stubTrue } from 'lodash';
import FinishedProductmenu from 'src/components/_dashboard/blog/FinishedProductmenu';

export default function MaterialFinishedProducts({prodId, viewFinishedProducts, unitName, unitQty,expectedPrice}) {
  const theme = useTheme();
  const componentRef = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const id = searchParams.get('id');
  const editId = parseInt(prodId);
  const [loading, setLoading] = useState(false)
  const [refreshData, setRefreshData] = useState(false)
  const [editFinishedProductId, setEditFinishedProductId] = useState()
  const [editData, setEditData] = useState([])
  const [openAddFinishedProduct, setOpenAddFinishedProduct] = useState(false)
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [orderBy, setOrderBy] = useState('id');
  const [filterName, setFilterName] = useState('');
  const [filterSource, setFilterSource] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [ProductList, setProductList] = useState([]);
  const receivedData = location.state?.data;
  const { enqueueSnackbar } = useSnackbar();
  const userData = JSON.parse(localStorage.getItem('user'))
  const userId = userData.user_id
  const explodedPermissions =userData?.exploded_permissions

  
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'qrcode', label: 'QR Code', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'unit_cost', label: `${unitName} Cost`, alignRight: false },
  // { id: 'unit_qty', label: 'Quantity', alignRight: false },
  { id: 'qty', label: `Total ${unitName}`, alignRight: false },
  { id: 'total_cost', label: 'Total Cost', alignRight: false },
  { id: 'source', label: 'Source', alignRight: false },
  { id: 'vendor', label: 'Vendor Name', alignRight: false },
  // { id: 'timestamp', label: 'Added On', alignRight: false },
   { id: 'status', label: '', alignRight: false },
  { id:'options', label: '', alignRight: false},      
];

// ----------------------------------------------------------------------

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  useEffect( async () => {
    setLoading(true)
    try {
      const response = await axios.post(`${REST_API_END_POINT}product/get-finished-products`,{editId});
      const productListData = response.data.results;
      if(productListData){
        setProductList(productListData);
        // console.log("productListData : ",productListData);
      }
      else{
        setProductList([]);
      }
    }
      catch (error) {
      console.error("Error fetching data:", error);
    }
    finally{
      setLoading(false);
    }
  }, [receivedData, refreshData]);

  const fetchDataById = async () => {
    try{
      const response = await axios.post(`${REST_API_END_POINT}product/get-finished-product-by-id`,{editFinishedProductId});
      if(response.data.status === 1) {
        setEditData(response.data.results[0])
      }
      else{
        setEditData([])
      }
    }catch(error){
      console.log(error);
    }
  }

  useEffect(()=>{
    if(editFinishedProductId){
      fetchDataById()
    }
  },[editFinishedProductId])

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function applySortFilter(array, comparator, query, source) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    if (source) {
      if (source === 'Inventory') {
        return filter(array, (_user) => _user.source === 0);
      }
      else if (source === 'Vendor') {
        return filter(array, (_user) => _user.source === 1);
      }
    }
    return stabilizedThis.map((el) => el[0]);
  }
  
  const NewCommunitySchema = Yup.object().shape({
    unitCost: Yup.number().typeError('Please enter a valid price').required(`Please enter the ${unitName} cost`),
    qty: Yup.number().typeError('Please enter a valid qty').required('Quantity is required'),
  });
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      unitCost: (editFinishedProductId && editData) ? editData.unit_cost : expectedPrice||"",
      qty:(editFinishedProductId && editData) ? editData.qty : '',
      total:(editFinishedProductId && editData) ? editData.total_cost : '',
    },
    validationSchema: NewCommunitySchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors, setFieldError }) => {
      try {
        let response = '';
        if(editFinishedProductId){
          const materialName = editData?.product_name
          response = await axios.post(`${REST_API_END_POINT}product/edit-finished-product`, {values,userId,editFinishedProductId,materialName})
        }else{
          if(unitName === 'Unit') {
            values.qty = values.qty
            values.unit_qty = values.qty
          }else{
            values.unit_qty = values.qty
            values.qty = values.qty*unitQty
          }
          response = await axios.post(`${REST_API_END_POINT}product/add-finished-product`, {values,userId,editId})
        }
        // console.log("Edit community type response : ", response.data);
        if (response.data.status === 1) {
          enqueueSnackbar(response.data.message, { variant: 'success' });
          resetForm();
          setSubmitting(false);
          setOpenAddFinishedProduct(false)
          setEditData([])
          setEditFinishedProductId()
          setRefreshData(!refreshData)
        }
        else {
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
      } catch (error) {
        console.error("Error : ", error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });
  
  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = slicedData?.map((n) => n.id);
      const newSelectedNames = slicedData?.map((n) => n.name);
      setSelected(newSelecteds);
      setSelectedNames(newSelectedNames);
      return;
    }
    setSelected([]);
    setSelectedNames([]);
  };

  const handleClick = (event, id,name) => {
    const selectedIndex = selected?.indexOf(id);
    const selectedNameIndex = selectedNames?.indexOf(name);
    let newSelected = [];
    let newselectedNames = [];
    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, id);
      newselectedNames = newselectedNames?.concat(selectedNames, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected?.slice(1));
      newselectedNames = newselectedNames?.concat(selectedNames?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected?.concat(selected?.slice(0, -1));
      newselectedNames = newselectedNames?.concat(selectedNames?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(selected?.slice(0, selectedIndex), selected?.slice(selectedIndex + 1));
      newselectedNames = newselectedNames?.concat(selectedNames?.slice(0, selectedNameIndex), selectedNames?.slice(selectedNameIndex + 1));
    }
    setSelected(newSelected);
    setSelectedNames(newselectedNames);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSelected([]);
    setSelectedNames([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0)
  };

  const handleFilterBySource = (event) => {
    setFilterSource(event);
    setPage(0)
  };

  const handleDeleteUser = (userId) => {
    dispatch(deleteUser(userId));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ProductList?.length) : 0;
  const filteredUsers = applySortFilter(ProductList?.filter(item => item.qty > 0), getComparator(order, orderBy), filterName, filterSource);
  const slicedData = filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const isUserNotFound = filteredUsers?.length === 0;

  return (
    <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
          <Typography sx={{py:3}} variant='h6'>Finished Products</Typography>
          {viewFinishedProducts ? null :<Button 
            disabled={explodedPermissions?.some(permission => ['Add Finished Product'].includes(permission)) ? false : true}
            onClick={()=>{ setOpenAddFinishedProduct(true) }}
            variant="contained" startIcon={<Icon icon={plusFill} />} >
            Add Finished Product
          </Button>}
        </Stack>
        <Card>
          <MaterialInventoryToolbar 
            numSelected={selected?.length} 
            filterName={filterName} 
            onFilterName={handleFilterByName}
            filterSource={filterSource}
            onFilterSource={handleFilterBySource}
            selectedName={selectedNames} 
            selected={selected} 
            onDelete={() => {setSelected([]);setPage(0);}}
            item="finished products"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
              <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={slicedData?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                {loading ? (
                  <CircularProgress color="primary" variant="indeterminate" />
                ) : (
                  <>
                  {slicedData?.map((row) => {
                    let { id,	barcode, name, timestamp,unit_qty, status, unit_cost,qty,total_cost, authorized_by,source,vendor } = row;
                    const isWithinLast24Hours = (timestamp) => {
                      const twentyFourHoursAgo = moment().subtract(24, 'hours');
                      const timestampMoment = moment.unix(timestamp); // Convert timestamp to moment object
                      return timestampMoment.isAfter(twentyFourHoursAgo);
                    };
                    const withinLast24Hours = isWithinLast24Hours(timestamp);
                    const isItemSelected = selected?.indexOf(id) !== -1;
                
                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell>
                        
                          {withinLast24Hours && <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={ 'info' }
                          >
                            New
                          </Label>}
                        </TableCell>
                        <TableCell align="center">  
                          <Tooltip title={barcode}>                                     
                            <QRCode
                              value={`${PATH_DASHBOARD.products.viewFinished}/${id}`}
                              style={{ cursor: 'pointer' }}                             
                              size={70}
                              bgColor={'#ffffff'}  
                              fgColor={'#000000'}
                            />
                          </Tooltip>    
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="center">{unit_cost ? unit_cost?.toFixed(2) : 0.00}</TableCell>
                        {/* <TableCell align="center">{unit_qty}</TableCell> */}
                        <TableCell align="center">{qty ? qty?.toFixed(2) : 0.00}</TableCell>
                        <TableCell align="center">{total_cost ? total_cost?.toFixed(2) : 0.00}</TableCell>                      
                        <TableCell align="center">{source==0 ? 'Inventory' : 'Vendor'}</TableCell>  
                        <TableCell align="center">{vendor?vendor:""}</TableCell>                            
                        {/* <TableCell align="center">{moment.unix(timestamp).format('DD-MM-YYYY')}</TableCell> */}
                        <TableCell align="center">
                        {/* {(source === 0) ? <MaterialInventoryMenu onDelete={() => handleDeleteUser(id)} userName={name} id={id} status={status} fromFinishedProducts onEdit={(id)=>{setEditFinishedProductId(id);setOpenAddFinishedProduct(true) }} onRefresh={()=>{setRefreshData(!refreshData)}} /> : ''} */}
                        {(source === 0) ? (viewFinishedProducts ? '' : <MaterialInventoryMenu onDelete={() => handleDeleteUser(id)} userName={name} id={id} status={status} fromFinishedProducts noEdit={true}  onEdit={(id)=>{setEditFinishedProductId(id);setOpenAddFinishedProduct(true) }} onRefresh={()=>{setRefreshData(!refreshData)}} />) : ''}
                        </TableCell>
                        <TableCell align="right">
                        <FinishedProductmenu
                          url={`https://dev.signwisesolutions.com/view-finished-products/`}
                           id={id}  
                        />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                  </>
                    )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} msg="Finished Products"/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Modal
        modalTitle={editFinishedProductId ? 'Edit Finished Product' : 'Add Finished Product'}
        open={openAddFinishedProduct}
        handleClose={() => {setOpenAddFinishedProduct(false);}}
      >
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} md={8}>
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      label={unitName+' Cost'}
                      type="number"
                      onKeyPress={(event) => {
                        // console.log('errorFieldId------',errorFieldId)
                        if (event.key === '0' && event.target.value === '0' ) {
                          event.preventDefault();
                        }
                        if (event.key === '0' && event.target.value === '' ) {
                          event.preventDefault();
                        }
                        if (event.key === '-' ) {
                          event.preventDefault();
                        }
                      }}
                      // {...getFieldProps('unitCost')}
                      // InputLabelProps={{ style: { color: theme.palette.text.primary } }}
                      value={values.unitCost}
                      disabled
                      onChange={(e)=>{
                        setFieldValue('unitCost',e.target.value);
                        if(unitName === 'Unit') {
                          setFieldValue('total',(e.target.value * values.qty)?.toFixed(2)) 
                        }else{
                          setFieldValue('total',(e.target.value * (unitQty * values.qty))?.toFixed(2))
                        }
                      }}
                      error={Boolean(touched.unitCost && errors.unitCost)}
                      helperText={touched.unitCost && errors.unitCost}
                    />
                    {unitName === 'Unit' ? null :
                    <TextField
                      fullWidth
                      label={'Number of '+unitName}
                      type="number"
                      value={unitQty || 0}
                      // {...getFieldProps('unit_qty')}
                      error={Boolean(touched.unit_qty && errors.unit_qty)}
                      helperText={touched.unit_qty && errors.unit_qty}
                      // InputLabelProps={{ style: { color: theme.palette.text.primary } }}
                      InputProps={{ readOnly:true }}
                    />}
                    <TextField
                      fullWidth
                      label="Quantity"
                      type="number"
                      onKeyPress={(event) => {
                        // console.log('errorFieldId------',errorFieldId)
                        if (event.key === '0' && event.target.value === '0' ) {
                          event.preventDefault();
                        }
                        if (event.key === '0' && event.target.value === '' ) {
                          event.preventDefault();
                        }
                        if (event.key === '-' ) {
                          event.preventDefault();
                        }
                      }}
                      // {...getFieldProps('qty')}
                      // InputLabelProps={{ style: { color: theme.palette.text.primary } }}
                      value={values.qty}
                      onChange={(e)=>{
                        setFieldValue('qty',e.target.value); 
                        if(unitName === 'Unit') {
                          setFieldValue('total',(values.unitCost * e.target.value)?.toFixed(2))
                        }else{
                          setFieldValue('total',(values.unitCost * (unitQty * e.target.value))?.toFixed(2))
                        }
                      }}
                      error={Boolean(touched.qty && errors.qty)}
                      helperText={touched.qty && errors.qty}
                    />
                    <TextField
                      fullWidth
                      label="Total"
                      type="number"
                      {...getFieldProps('total')}
                      error={Boolean(touched.total && errors.total)}
                      helperText={touched.total && errors.total}
                      InputLabelProps={{ style: { color: theme.palette.text.primary } }}
                      InputProps={{ readOnly:true }}
                    />
                  </Stack>

                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                      {editFinishedProductId ? 'Save Changes' :'Add Finished Product'}
                    </LoadingButton>
                    <Button variant="outlined" onClick={()=>{setOpenAddFinishedProduct(false); formik.resetForm(); setEditData([]); setEditFinishedProductId()}} sx={{ marginLeft: '10px' }}>
                      Cancel
                    </Button>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Modal>
    </>
  );
}
